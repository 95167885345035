import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
import Notification from '@/modules/common/services/notification.service'
import GtrNewAttendeeForm from './create-attendee/new-attendee.form.vue'
import GtrNewCustomFieldForm from './create-custom-field/new-custom-field.form.vue'
import GtrEventLayout from '@/modules/common/views/layouts/level-two/event/event.layout'
import { mixins } from 'vue-class-component'
import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import BadgesMixin from '@/modules/level-two/views/event/modules/badges/mixins/badges.mixin'
import Container from 'typedi'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import IGtrShowIf from '@/interfaces/gtr-show-if.interface'
import draggable from 'vuedraggable'
import { DataTableHeader } from 'vuetify'

@Component({
  name: 'GtrEventAttendeesView',
  computed: {
    ...mapState('attendee', ['attendees', 'attendeesWithReferralCode', 'deleted_attendees', 'attendeesWithPromoCode', 'attendeesWithOptionSelection', 'attendeesListViewWithCustomField']),
    ...mapState('formbuilder', ['eventFields']),
    ...mapState('event', ['event', 'eventAllContent']),
    ...mapState('registration', ['participantSearchResults', 'savedSearches']),
    ...mapState('email', ['emails', 'scheduledEmailParticipants']),
    ...mapState('badges', ['badges', 'averyBadgesExport']),
    ...mapState('setting', ['settings'])
  },
  components: {
    'gtr-new-attendee-form': GtrNewAttendeeForm,
    'gtr-new-custom-field-form': GtrNewCustomFieldForm,
    vuedraggable: draggable
  }
})
export default class GtrEventAttendeesView extends mixins(GtrSuper, BadgesMixin) {
  participantSearchResults!: Record<string, any>
  emails!: Record<string, any>
  badges!: Record<string, any>
  averyBadgesExport!: Record<string, any>

  // }
  get activeSavedSearches () {
    return ((this as any).savedSearches || []).filter(search => search.active)
  }

  get attendeesLoaded () {
    return this.$data.tables.attendees.items.length > 0
  }

  get emailOptions () {
    if (this.emails) {
      const emailOptions: any[] = []
      for (let i = 0; i < this.emails.length; i++) {
        const email = this.emails[i]
        const emailName = this.handleEmailName(email.value.page_name)
        const emailValue = email.value.page_name
        if (emailValue !== 'admin_cancellation_notification' && emailValue !== 'admin_registration_notification') {
          emailOptions.push({
            text: emailName,
            value: emailValue
          })
        }
      }
      return emailOptions.sort((a, b) => a.text >= b.text ? 1 : -1)
    }
    return []
  }

  // addToBulkSelectedActiveParticipantUuids (uuid: string) {
  //   if (this.$data.bulkSelectedActiveParticipantUuids.findIndex(curUuid => curUuid === uuid) !== -1) {
  //     this.$data.bulkSelectedActiveParticipantUuids = this.$data.bulkSelectedActiveParticipantUuids.filter(curUuid => curUuid !== uuid)
  //   } else {
  //     this.$data.bulkSelectedActiveParticipantUuids.push(uuid)
  //   }

  get isRegActive () {
    const reg: Record<string, any> = this.$store.state.module.activatedEventModules.REGISTRATION
    return reg.enabled
  }

  get isBadgesActive () {
    const badges: Record<string, any> = this.$store.state.module.activatedEventModules.BADGES
    return badges.enabled
  }

  get isLeadsActive () {
    const leads: Record<string, any> = this.$store.state.module.activatedEventModules.LEADS
    return leads.enabled
  }

  get activeBadges () {
    if (this.badges) {
      return this.badges.filter(badge => badge.active)
    }
  }

  get eventFieldsNew () {
    const prohibitedFields = [
      'active',
      'auto_assign',
      'auto_create_checkout_time',
      'can_export_certificates',
      'capacity',
      'category',
      'ceu_min_time',
      'course_no',
      'created_at',
      'deleted_at',
      'deleted_by',
      'deletion_reason',
      'description',
      'flag',
      'flag2',
      'order',
      'password',
      'password_reset_token',
      'reporting_type',
      'room',
      'scan_modes',
      'scantrakk_code_id',
      'session_date',
      'session_day',
      'session_id',
      'session_type',
      'show_after',
      'show_agenda',
      'show_before',
      'show_ceu',
      'show_id',
      'show_if',
      'show_sessions_page',
      'start_date',
      'stripe_customer_id',
      'sync_id',
      'sync_updated',
      'sync_updated_id',
      'third_party_data',
      'time_emailed',
      'transferred_from',
      'unknown_participant_id',
      'updated_at',
      'updated_from_sync',
      'use_show_if'
    ]
    const eventFieldsFiltered = (this.$store.state.formbuilder.eventFields || []).filter(f => !prohibitedFields.includes(f.field))
    return eventFieldsFiltered.sort((a, b) => (a.label || '').toLowerCase().localeCompare((b.label || '').toLowerCase())).map(obj => ({
      ...obj,
      display: obj.label
    }))
  }

  get activeBadgeSize () {
    if (this.activeBadges) {
      const sizes: any = []
      for (let i = 0; i < this.activeBadges.length; i++) {
        const badge = this.activeBadges[i]
        if (i === 0) {
          sizes.push(badge.badge_properties.size)
        } else {
          for (let j = 0; j < sizes.length; j++) {
            const size = sizes[j]
            if (badge.badge_properties.size !== size) {
              sizes.push(badge.badge_properties.size)
            }
          }
        }
      }
      return sizes
    }
    return []
  }

  get isAverySupported () {
    if (this.activeBadgeSize) {
      for (let i = 0; i < this.activeBadgeSize.length; i++) {
        if (this.supportedAverySizes.includes(this.activeBadgeSize[i])) {
          return true
        }
      }
    }
    return false
  }

  /**
   * This could change, but we don't send selectedAveryTemplate to BE.
   * Instead, we send 1 Avery template based off of badge template size.
   */
  get averyTemplateType () {
    if (this.activeBadgeSize && this.activeBadgeSize.length === 1) {
      if (this.activeBadgeSize[0] === '3-3/8" x 2-1/3"') {
        return '5395'
      } else if (this.activeBadgeSize[0] === '3-1/2" x 2-1/4"') {
        return '5390'
      } else if (this.activeBadgeSize[0] === '4" x 2"') {
        return '5163'
      } else if (this.activeBadgeSize[0] === '4" x 3"') {
        return '5384'
      }
    } else {
      return ''
    }
  }

  get compatibleAveryTemplates () {
    if (this.activeBadges) {
      const compatibleTemplates: any = []
      for (let i = 0; i < this.activeBadges.length; i++) {
        const badge = this.activeBadges[i]
        for (let j = 0; j < this.averyTemplates.length; j++) { // averyTemplates from badges.mixin.ts
          const template = this.averyTemplates[j]
          if (template.size === badge.badge_properties.size) {
            compatibleTemplates.push(template.number)
          }
        }
      }
      return compatibleTemplates
    }
    return []
  }

  data () {
    return {
      loadingParticipants: true,
      editFields: false,
      listViewFieldsLoad: null,
      listViewFieldAdd: null,
      settingsListViewFields: null,
      submitting: false,
      viewDeletedToggleButton: undefined,
      participantTableTab: 0,
      showSavedSearchEdit: false,
      activeSavedSearchUuid: '',
      activeSavedSearchName: '',
      activeSavedSearchShowIf: {
        visible: false,
        global_type: '',
        type: '',
        field: '',
        operator: '',
        value: '',
        group_operator: '',
        group_items: []
      },
      tables: {
        attendees: {
          selected: [],
          headers: [],
          items: []
        },
        deleted: {
          headers: [
            {
              text: 'First name',
              align: 'start',
              sortable: false,
              value: 'first_name'
            },
            {
              text: 'Last name',
              align: 'start',
              sortable: false,
              value: 'last_name'
            },
            {
              text: 'Email',
              align: 'start',
              sortable: false,
              value: 'email'
            },
            {
              text: '',
              value: 'actions',
              searchable: false,
              sortable: false,
              width: '90px'
            }
          ],
          items: []
        }
      },
      loading: false,
      forms: {
        showNewAttendeeForm: false,
        showNewCustomFieldForm: false
      },
      showSearchForm: false,
      showSendBulkEmailModal: false,
      list_view_fields: null,
      showDeleted: false,
      attendeesToDelete: null,
      show_if: {
        visible: false,
        global_type: '',
        type: '',
        field: '',
        operator: '',
        value: '',
        group_operator: '',
        group_items: []
      },
      optionGroups: [],
      first_name: '',
      last_name: '',
      email: '',
      status: '',
      filterFields: {},
      headers: [],
      custom_fields: [],
      search: '',
      referral_code: null,
      saveSearchModal: false,
      saveSearchName: '',
      deleteSavedSearchModal: false,
      deleteSavedSearchUuid: null,
      bulkSelectedActiveParticipants: [],
      bulkEmailToSend: null,
      showAddBulkLeadsActivationCodeModal: false,
      addBulkLeadsActivationCodeData: {
        cap: 1,
        sendEmail: false
      },
      showBadgesExportModal: false,
      selectedAveryTemplate: null,
      exportingAveryBadgesPDF: false
    }
  }

  created () {
    this.$emit('update:layout', GtrEventLayout)
    this.$bus.$on('gtr.event.content_all', this.getListViewFields)
    this.$store.commit('badges/SET_AVERY_BADGES_EXPORT_RESPONSE', {})
  }

  async mounted () {
    try {
      this.$store.dispatch('common/showLoader', { value: true })
      this.$store.dispatch('email/fetchEmails', this.$route.params.event_uuid)
      this.fetchAllEventContent()
      if (this.$route.params.scheduled_email_uuid) {
        await this.fetchScheduledEmailParticipants()
        await this.processTableDataScheduledEmailAttendees()
      } else if (!this.$route.params.search_field && !this.$route.params.search_value && !this.$route.params.referral_module && !this.$route.params.option_group_uuid) {
        this.fetchAttendeesListView()
      }
      this.getListViewFields()
      this.fetchSavedSearches()
      this.fetchCustomFields()
      if (this.isBadgesActive) {
        this.getBadgeList()
      }
      if (this.$route.name === 'level-two.event.attendees-pending') {
        this.$data.participantTableTab = 4
        this.handleFilterPending()
      }
      if (this.$store.state.event !== undefined) {
        const eventAllContent = this.$store.state.event.eventAllContent
        this.$data.settingsListViewFields = eventAllContent.settings.list_view_fields
      }
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$store.dispatch('common/hideLoader')
    }
    this.$data.addBulkLeadsActivationCodeData.cap = this.defaultActivationQty
  }

  get pendingEnabled () {
    if (this.$store.state.event !== undefined) {
      const eventAllContent = this.$store.state.event.eventAllContent
      return eventAllContent.settings.registration_approval_required
    }
    return false
  }

  get onPendingTab () {
    return this.$data.participantTableTab === 4
  }

  async fetchSavedSearches () {
    try {
      this.$data.loading = true
      this.$store.dispatch('registration/getSearches', this.$route.params.event_uuid)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  async fetchCustomFields () {
    try {
      this.$data.loading = true
      const temp_custom_fields = await this.$store.dispatch('event/loadCustomFields', { event_uuid: this.$route.params.event_uuid })
      this.$data.custom_fields = []
      temp_custom_fields.forEach(item => {
        if (item.field.includes('custom_')) {
          this.$data.custom_fields.push(item)
        }
      })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  setShowIfObject (payload: IGtrShowIf) {
    this.$data.show_if = payload
  }

  setTabByParticipantStatus (status: string) {
    switch (status) {
      case 'Complete':
        this.$data.participantTableTab = 1
        break
      case 'Incomplete':
        this.$data.participantTableTab = 2
        break
      case 'Canceled':
        this.$data.participantTableTab = 3
        break
      case 'Pending':
        this.$data.participantTableTab = 4
        break
    }
  }

  @Watch('$route', { immediate: true })
  async onAttendeesLoadedChange (payload: any) {
    if (payload && this.$route.params.search_field && this.$route.params.search_value) {
      if (this.$route.params.search_field === 'referral_code') {
        this.$data.referral_code = this.$route.params.search_value
        await this.fetchAttendeesWithReferralCode()
        this.$data.tables.attendees.items = this.$store.state.attendee.attendeesWithReferralCode
      } else if (this.$route.params.search_field === 'promo_code') {
        await this.fetchAttendeesWithPromoCode()
        this.$data.tables.attendees.items = this.$store.state.attendee.attendeesWithPromoCode
      } else if (['barcode'].includes(this.$route.params.search_field)) {
        await this.fetchAttendeesListViewWithCustomField(this.$route.params.search_field, this.$route.params.search_value)
        this.$data.tables.attendees.items = this.$store.state.attendee.attendeesListViewWithCustomField
      } else {
        await this.fetchAttendeesListView()
        this.processTableDataAttendeesListView()
        this.$data.tables.attendees.items = this.$data.tables.attendees.items.filter(attendee => attendee[this.$route.params.search_field] === this.$route.params.search_value)
        if (this.$route.params.search_field === 'status' && this.$route.params.search_value) {
          this.setTabByParticipantStatus(this.$route.params.search_value)
        }
      }
    } else if (payload && this.$route.params.referral_module) {
      this.$data.referral_code = this.$route.params.referral_value
      await this.fetchAttendeesWithReferralCodeByStatus(this.$route.params.referral_module, this.$route.params.participant_status)
      this.$data.tables.attendees.items = this.$store.state.attendee.attendeesWithReferralCode
      this.setTabByParticipantStatus(this.$route.params.participant_status)
    } else if (payload && this.$route.params.option_group_uuid && this.$route.params.option_uuid) {
      this.$data.tables.attendees.items = []
      await this.fetchAttendeesWithOptionSelection()
      this.$data.tables.attendees.items = this.$store.state.attendee.attendeesWithOptionSelection
    }
  }

  async handleViewDeleted () {
    if (this.$data.viewDeletedToggleButton === 0) {
      this.$data.showDeleted = false
    } else {
      await this.fetchDeletedAttendees()
      await this.processDeletedAttendeesTableData()
      this.$data.showDeleted = true
    }
  }

  handleDeleteAttendeesEvent () {
    this.$data.attendeesToDelete = this.$data.tables.attendees.selected
  }

  async handleDeleteAttendeesAction (payload) {
    if (payload.confirm) {
      try {
        this.$data.loading = true
        let records: any = []
        this.$data.attendeesToDelete.forEach(record => {
          records.push(record.uuid)
        })
        const attendeePayload = {
          event_uuid: this.$route.params.event_uuid,
          records: records
        }
        await this.$store.dispatch('attendee/deleteAttendees', attendeePayload)
        await this.fetchAttendeesListView()
        await this.processTableDataAttendeesListView()
        if (this.$data.attendeesToDelete.length === 1) {
          Container.get(Notification).success('Registration successfully deleted.')
        } else {
          Container.get(Notification).success('Registrations successfully deleted.')
        }
        this.$data.attendeesToDelete = null
        this.$data.tables.attendees.selected = []
        records = []
      } catch (error) {
        Container.get(ErrorHandlerService).error(error)
      } finally {
        this.$data.loading = false
      }
    }
  }

  getArrayFromOptionGroupOptionsByUUID (payload: any) {
    return this.optionGroupOptionsByUUID()[payload]
  }

  async handleFilterAll () {
    this.$data.showSavedSearchEdit = false
    this.resetEditSavedSearchShowIf()
    await this.processTableDataAttendeesListView()
  }

  async handleFilterComplete () {
    this.$data.showSavedSearchEdit = false
    this.resetEditSavedSearchShowIf()
    await this.processTableDataAttendeesListView()
    this.$data.tables.attendees.items = this.$data.tables.attendees.items.filter(attendee => attendee.status === 'Complete')
  }

  async handleFilterIncomplete () {
    this.$data.showSavedSearchEdit = false
    this.resetEditSavedSearchShowIf()
    await this.processTableDataAttendeesListView()
    this.$data.tables.attendees.items = this.$data.tables.attendees.items.filter(attendee => attendee.status === 'Incomplete')
  }

  async handleFilterCanceled () {
    this.$data.showSavedSearchEdit = false
    this.resetEditSavedSearchShowIf()
    await this.processTableDataAttendeesListView()
    this.$data.tables.attendees.items = this.$data.tables.attendees.items.filter(attendee => attendee.status === 'Canceled')
  }

  async handleFilterPending () {
    this.$data.showSavedSearchEdit = false
    this.resetEditSavedSearchShowIf()
    await this.processTableDataAttendeesListView()
    this.$data.tables.attendees.items = this.$data.tables.attendees.items.filter(attendee => attendee.status === 'Pending')
  }

  async handleCloseNewCustomFieldForm () {
    this.$data.bulkSelectedActiveParticipants = []
    this.$data.forms.showNewCustomFieldForm = false
    await this.fetchCustomFields()
  }

  handleShowNewCustomFieldForm () {
    this.$data.forms.showNewCustomFieldForm = true
  }

  resetEditSavedSearchShowIf () {
    Object.assign(this.$data.activeSavedSearchShowIf, {
      visible: false,
      global_type: '',
      type: '',
      field: '',
      operator: '',
      value: '',
      group_operator: '',
      group_items: []
    })
  }

  handleSaveSearchClick () {
    this.$data.saveSearchModal = true
  }

  async handleFilterSavedSearch (savedSearch: any) {
    this.$data.submitting = false
    this.$data.loading = true
    this.$data.tables.attendees.items = []
    this.$data.showSavedSearchEdit = true
    const { criteria, name, uuid } = savedSearch
    this.$data.activeSavedSearchName = name
    this.$data.activeSavedSearchUuid = uuid
    Object.assign(this.$data.activeSavedSearchShowIf, criteria)
    try {
      const response = await this.$store.dispatch('registration/doSearch', {
        event_uuid: this.$route.params.event_uuid,
        data: criteria
      })
      await this.processSearchData(response.data.uuid)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.submitting = false
      this.$data.loading = false
    }
  }

  handleShowNewAttendeeForm () {
    this.$data.forms.showNewAttendeeForm = true
  }

  handleCloseNewAttendeeForm () {
    this.$data.forms.showNewAttendeeForm = false
  }

  async handleAction () {
    await this.fetchAttendeesListView()
    await this.processTableDataAttendeesListView()
  }

  handleShowSearchForm () {
    this.$data.show_if.visible = true
    this.$data.showSavedSearchEdit = false
  }

  handleCloseSearchForm () {
    this.$data.showSearchForm = false
  }

  async loadParticipants () {
    this.$data.listViewFieldsLoad = this.$data.settingsListViewFields
    this.getListViewFields()
    await this.fetchAttendeesListView()
    await this.processTableDataAttendeesListView()
  }

  async updateListViewSetting () {
    let settingsCurrent: any = {}
    try {
      const settings = await this.$store.dispatch('setting/getSettings', { event_uuid: this.$route.params.event_uuid })
      settingsCurrent = settings.data.page_data
      const listViewSetting = settingsCurrent.list_view_fields
      for (const regType in listViewSetting) {
        if (listViewSetting.hasOwnProperty(regType)) {
          settingsCurrent.list_view_fields[regType] = this.$data.settingsListViewFields
        }
      }
    } catch {
      // no setting exist
      settingsCurrent.list_view_fields = {}
      settingsCurrent.list_view_fields._default = this.$data.settingsListViewFields
    }
    await this.$store.dispatch('setting/saveSettings', { event_uuid: this.$route.params.event_uuid, data: settingsCurrent })
    Container.get(Notification).success('Succesfully Updated List View Setting')
    this.loadParticipants()
  }

  async saveSearch () {
    try {
      this.$data.loading = true
      await this.$store.dispatch('registration/saveSearch', {
        event_uuid: this.$route.params.event_uuid,
        data: {
          name: this.$data.saveSearchName,
          criteria: this.$data.show_if
        }
      })
      await this.fetchSavedSearches()
      this.handleSaveSearchClose()
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  async submit () {
    try {
      this.$data.submitting = true
      this.$data.loading = true
      this.$data.tables.attendees.items = []
      const response = await this.$store.dispatch('registration/doSearch', {
        event_uuid: this.$route.params.event_uuid,
        data: this.$data.show_if
      })
      await this.$store.dispatch('registration/getParticipantSearchResults', {
        event_uuid: this.$route.params.event_uuid,
        search_uuid: response.data.uuid
      })
      await this.processSearchData(response.data.uuid)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.submitting = false
      this.$data.loading = false
    }
  }

  async updateSavedSearch (savedSearchUuid: string, criteria: any, name: string) {
    this.$data.loading = true
    try {
      await this.$store.dispatch('registration/updateSearch', {
        event_uuid: this.$route.params.event_uuid,
        savedSearchUuid,
        data: {
          name,
          criteria,
          active: 1
        }
      })
      this.fetchSavedSearches()
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  async deleteSavedSearch (savedSearchUuid: string) {
    try {
      this.$data.loading = true
      await this.$store.dispatch('registration/updateSearch', {
        event_uuid: this.$route.params.event_uuid,
        savedSearchUuid,
        data: {
          active: 0
        }
      })
      this.$data.showSavedSearchEdit = false
      await this.fetchSavedSearches()
      this.handleSaveSearchClose()
      this.handleDeleteSavedSearchClose()
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  optionGroupOptions (selection: any) {
    return this.optionGroupOptionsByUUID()[selection]
  }

  async getBadgeList () {
    try {
      this.$data.loading = true
      await this.$store.dispatch('badges/fetchBadges', { event_uuid: this.$route.params.event_uuid })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  handleOpenBadgesExportModal () {
    this.$data.showBadgesExportModal = true
  }

  handleCloseBadgesExportModal () {
    this.$data.showBadgesExportModal = false
    this.$data.selectedAveryTemplate = null
    setTimeout(() => {
      this.$data.exportingAveryBadgesPDF = false
    }, 1000)
  }

  async handleExportBadgesPDF () {
    try {
      this.$data.exportingAveryBadgesPDF = true
      const participantUuids: any = []
      if (this.$data.bulkSelectedActiveParticipants?.length > 0) {
        this.$data.bulkSelectedActiveParticipants.forEach(participant => participantUuids.push(participant.uuid))
      }
      const payload = {
        event_uuid: this.$route.params.event_uuid,
        data: {
          type: this.averyTemplateType,
          participant_uuids: participantUuids.length > 0 ? participantUuids : null
        }
      }
      await this.$store.dispatch('badges/createAveryBadgesExport', payload)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  @Watch('averyBadgesExport', { immediate: true })
  onAveryBadgesExportChange (payload) {
    if (payload) {
      this.handleCloseBadgesExportModal()
    }
  }

  private addListViewField () {
    const mainField = this.$data.listViewFieldAdd
    this.$data.settingsListViewFields.push({ field: mainField.field, label: { en: mainField.label }, type: 'field' })
    this.$data.listViewFieldAdd = null
  }

  private removeListViewField (index) {
    this.$data.settingsListViewFields.splice(index, 1)
  }

  private handleSaveSearchClose () {
    const observer = (this.$refs.saveSearchForm as any) ?? null
    if (observer) {
      observer.reset()
    }
    this.$data.saveSearchModal = false
    this.$data.showSavedSearchEdit = false
    this.$data.show_if.visible = false
    this.$data.saveSearchName = ''
    this.$emit('close')
  }

  private async fetchAllEventContent () {
    try {
      this.$data.loading = true
      await this.$store.dispatch('event/getEventAllContent', this.$route.params.event_uuid)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  private async fetchAttendees () {
    try {
      this.$data.loading = true
      await this.$store.dispatch('attendee/fetchAttendees', this.$route.params.event_uuid)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  private async fetchAttendeesListView (field: string | null = null, value: string | null = null): Promise<void> {
    try {
      this.$data.loadingParticipants = true
      const listViewFields = this.$data.settingsListViewFields || null

      // Fetch the total number of records with a limit of 1
      const initialResponse = await this.$store.dispatch('attendee/fetchAttendeesListViewDirect', {
        event_uuid: this.$route.params.event_uuid,
        listViewFields,
        skip: 0,
        limit: 1,
        field,
        value,
        meta: 1
      })

      const totalResults = initialResponse.data.total
      const limit = 1000
      const totalPages = Math.ceil(totalResults / limit)

      // Create an array of Promises for fetching attendees in parallel
      const promises: Promise<any>[] = []
      const responses: any[] = []
      const participantsInOrder = {}
      for (let i = 0; i < totalPages; i++) {
        const promise = this.$store.dispatch('attendee/fetchAttendeesListViewDirect', {
          event_uuid: this.$route.params.event_uuid,
          listViewFields,
          skip: i * limit,
          limit,
          field,
          value
        })

        promises.push(promise)
        promise.then(response => {
          participantsInOrder[i] = response.data
          // Commit the response data to the store
          this.$store.commit('attendee/APPEND_ATTENDEES', response.data)

          // Callback: Process the table data and other actions after each call
          this.processTableDataAttendeesListView()
          this.getListViewFields()
          this.$store.dispatch('common/hideLoader')
          this.filterBasedOnTab()
        })
        responses.push(promise)
      }
      // Wait for all requests to settle
      await Promise.all(promises)
      const attendees: any = []
      for (let i = 0; i < totalPages; i++) {
        for (let p = 0; p < participantsInOrder[i].length; p++) {
          attendees.push(participantsInOrder[i][p])
        }
      }
      this.$store.commit('attendee/SET_ATTENDEES', attendees)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loadingParticipants = false
      this.processTableDataAttendeesListView()
      this.getListViewFields()
      this.$store.dispatch('common/hideLoader')
      this.filterBasedOnTab()
    }
  }

  private filterBasedOnTab () {
    if (this.$data.participantTableTab === 1) {
      this.handleFilterComplete()
    }
    if (this.$data.participantTableTab === 2) {
      this.handleFilterIncomplete()
    }
    if (this.$data.participantTableTab === 3) {
      this.handleFilterCanceled()
    }
    if (this.$data.participantTableTab === 4) {
      this.handleFilterPending()
    }
  }

  private async fetchAttendeesWithPromoCode () {
    try {
      this.$data.loading = true
      const payload: any = {
        event_uuid: this.$route.params.event_uuid,
        promo_code: this.$route.params.search_value
      }
      await this.$store.dispatch('attendee/fetchAttendeesWithPromoCode', payload)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  private async fetchAttendeesWithOptionSelection () {
    try {
      this.$data.loading = true
      const payload: any = {
        event_uuid: this.$route.params.event_uuid,
        option_group_uuid: this.$route.params.option_group_uuid,
        option_uuid: this.$route.params.option_uuid
      }
      if (this.$route.params.option_status_filter) {
        payload.status = this.$route.params.option_status_filter
      }
      await this.$store.dispatch('attendee/fetchAttendeesWithOptionSelection', payload)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  private async fetchAttendeesWithReferralCode () {
    try {
      this.$data.loading = true
      const payload: any = {
        event_uuid: this.$route.params.event_uuid,
        referral_code: this.$data.referral_code,
        status: false
      }
      await this.$store.dispatch('attendee/fetchAttendeesWithReferralCode', payload)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  private async fetchAttendeesWithReferralCodeByStatus (module, status) {
    try {
      this.$data.loading = true
      let statusName = 'status'
      if (module === 'leads') {
        statusName = 'lr_status'
      }
      const payload: any = {
        event_uuid: this.$route.params.event_uuid,
        referral_code: this.$data.referral_code,
        status,
        statusName
      }
      await this.$store.dispatch('attendee/fetchAttendeesWithReferralCode', payload)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  private async fetchAttendeesListViewWithCustomField (field, value) {
    return this.fetchAttendeesListView(field, value)
  }

  private async fetchDeletedAttendees () {
    try {
      this.$data.loading = true
      await this.$store.dispatch('attendee/fetchDeletedAttendees', this.$route.params.event_uuid)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  private async fetchScheduledEmailParticipants () {
    try {
      this.$data.loading = true
      this.$data.loadingParticipants = true
      await this.$store.dispatch('email/fetchScheduledEmailParticipants', {
        event_uuid: this.$route.params.event_uuid,
        scheduled_email_uuid: this.$route.params.scheduled_email_uuid
      })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
      this.$data.loadingParticipants = false
    }
  }

  private async processDeletedAttendeesTableData () {
    this.$data.tables.deleted.items = []
    if (this.$store.state.attendee.deleted_attendees.data) {
      this.$store.state.attendee.deleted_attendees.data.forEach(attendee => {
        const row: any = {}
        row.first_name = attendee.participant_data.first_name
        row.last_name = attendee.participant_data.last_name
        row.email = attendee.participant_data.email
        this.$data.tables.deleted.items.push(row)
      })
    }
  }

  private async processTableData () {
    this.$data.tables.attendees.items = []
    const eventAllContent = this.$store.state.event.eventAllContent
    if (this.$data.listViewFieldsLoad == null) {
      this.$data.listViewFieldsLoad = eventAllContent.settings.list_view_fields
    }
    this.$store.state.attendee.attendees.data.forEach(attendee => {
      if (this.$data.listViewFieldsLoad) {
        const row: any = {}
        this.$data.listViewFieldsLoad.forEach(field => {
          row[field.field] = attendee.participant_data[field.field]
          row.uuid = attendee.uuid
        })
        this.$data.tables.attendees.items.push(row)
      }
    })
  }

  private async processTableDataAttendeesListView () {
    this.$data.bulkSelectedActiveParticipants = []
    this.$data.tables.attendees.items = this.$store.state.attendee.attendees
  }

  private async processTableDataScheduledEmailAttendees () {
    this.$data.tables.attendees.items = (this as any).scheduledEmailParticipants
  }

  private async processSearchData (search_uuid: any) {
    let results: any = []
    let counter = 0
    const timer = ms => new Promise(resolve => setTimeout(resolve, ms))
    do {
      results = await this.$store.dispatch('registration/getParticipantSearchResults', {
        event_uuid: this.$route.params.event_uuid,
        search_uuid: search_uuid
      })
      counter++
      await timer(1000)
    } while (!['COMPLETE', 'FAILED'].includes(results?.data?.status) && counter < 10)
    if (results) {
      const participantSearchResults = this.$store.state.registration.participantSearchResults
      const eventAllContent = this.$store.state.event.eventAllContent
      if (this.$data.listViewFieldsLoad == null) {
        this.$data.listViewFieldsLoad = eventAllContent.settings.list_view_fields
      }
      participantSearchResults.records.forEach(attendee => {
        const row: any = {}
        this.$data.listViewFieldsLoad.forEach(field => {
          row[field.field] = attendee[field.field]
          row.uuid = attendee.uuid
        })
        this.$data.tables.attendees.items.push(row)
      })
    }
  }

  private getListViewFields () {
    this.$data.headers = []
    const eventAllContent = this.$store.state.event.eventAllContent
    if (this.$data.listViewFieldsLoad == null) {
      this.$data.listViewFieldsLoad = eventAllContent.settings.list_view_fields
    }
    if (this.$data.listViewFieldsLoad) {
      this.$data.listViewFieldsLoad.forEach((element: any) => {
        if (!this.$data.filterFields[element.field]) {
          Vue.set(this.$data.filterFields, element.field, {
            label: element.label.en,
            value: ''
          })
        }
        const header: DataTableHeader = {
          text: element.label.en,
          width: element.field === 'profile_photo' ? 105 : undefined,
          align: 'start',
          sortable: true,
          value: element.field
        }
        if (!this.$data.headers.includes(header)) {
          this.$data.headers.push(header)
        }
      })
      this.$data.headers.push({
        text: '',
        value: 'actions',
        sortable: false,
        width: '90px'
      })
    }
  }

  private handleAddBulkLeadsActivationCodeModalOpen () {
    this.$data.showAddBulkLeadsActivationCodeModal = true
  }

  private handleAddBulkLeadsActivationCodeModalClose () {
    this.$data.addBulkLeadsActivationCodeData.cap = this.defaultActivationQty
    this.$data.addBulkLeadsActivationCodeData.sendEmail = false
    this.$data.showAddBulkLeadsActivationCodeModal = false
  }

  get defaultActivationQty () {
    if (this.$store !== undefined && this.$store.state.event !== undefined && this.$store.state.event.eventAllContent !== undefined) {
      return this.$store.state.event.eventAllContent.lr_settings.default_activation_qty
    }
    return 3
  }

  async approveRegistrations () {
    try {
      const records = this.$data.bulkSelectedActiveParticipants.map(participant => participant.uuid)
      await this.$store.dispatch('registration/bulkApproveOrDecline', {
        event_uuid: this.$route.params.event_uuid,
        records,
        status: 'approve'
      })
      await this.fetchAttendeesListView()
      this.processTableDataAttendeesListView()
      this.handleFilterPending()
      Container.get(Notification).success('Participants succesfully Approved')
    } catch (e) {
      Container.get(ErrorHandlerService).error(e)
    }
  }

  async declineRegistrations () {
    try {
      const records = this.$data.bulkSelectedActiveParticipants.map(participant => participant.uuid)
      await this.$store.dispatch('registration/bulkApproveOrDecline', {
        event_uuid: this.$route.params.event_uuid,
        records,
        status: 'decline'
      })
      await this.fetchAttendeesListView()
      this.processTableDataAttendeesListView()
      this.handleFilterPending()
      Container.get(Notification).success('Participants succesfully Declined')
    } catch (e) {
      Container.get(ErrorHandlerService).error(e)
    }
  }

  private async addBulkLeadsActivationCode () {
    try {
      const participant_uuids = this.$data.bulkSelectedActiveParticipants.map(participant => participant.uuid)
      const data = {
        cap: this.$data.addBulkLeadsActivationCodeData.cap
      }
      await this.$store.dispatch('leads/addBulkLeadsActivationCode', {
        event_uuid: this.$route.params.event_uuid,
        participant_uuids,
        data
      })
      if (this.$data.addBulkLeadsActivationCodeData.sendEmail) {
        await this.$store.dispatch('email/sendBulkEmail', {
          event_uuid: this.$route.params.event_uuid,
          participant_uuids,
          email_name: 'lrorders_activation_codes'
        })
      }
      this.handleAddBulkLeadsActivationCodeModalClose()
      Container.get(Notification).success('Activation code successfully created')
    } catch (e) {
      Container.get(ErrorHandlerService).error(e)
    }
  }

  private handleSendBulkEmailOpen () {
    this.$data.showSendBulkEmailModal = true
  }

  private handleSendBulkEmailModalClose () {
    this.$data.bulkEmailToSend = null
    this.$data.showSendBulkEmailModal = false
  }

  private async sendBulkEmail () {
    try {
      const participant_uuids = this.$data.bulkSelectedActiveParticipants.map(participant => participant.uuid)
      await this.$store.dispatch('email/sendBulkEmail', {
        event_uuid: this.$route.params.event_uuid,
        participant_uuids,
        email_name: this.$data.bulkEmailToSend
      })
      this.handleSendBulkEmailModalClose()
      Container.get(Notification).success('Email successfully sent')
    } catch (e) {
      Container.get(ErrorHandlerService).error(e)
    }
  }

  private optionGroupOptionsByUUID () {
    const items: any[] = []
    for (let i = 0; i < this.$data.optionGroups.length; i++) {
      items[this.$data.optionGroups[i].uuid] = []
      for (let x = 0; x < this.$data.optionGroups[i].options.length; x++) {
        items[this.$data.optionGroups[i].uuid].push({
          text: this.$data.optionGroups[i].options[x].name,
          value: this.$data.optionGroups[i].options[x].uuid
        })
      }
    }
    return items
  }

  private processDeleteSavedSearchRequest (uuid) {
    this.$data.deleteSavedSearchUuid = uuid
    this.$data.deleteSavedSearchModal = true
  }

  private handleDeleteSavedSearchClose () {
    this.$data.deleteSavedSearchUuid = null
    this.$data.deleteSavedSearchModal = false
  }
}
